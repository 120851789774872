import {httpClient} from './httpClient';

const LOGIN_END_POINT = '/api/login_check';

const login = (username, password) => {
    return httpClient.post(LOGIN_END_POINT, {username, password})
}

export default {
    endpoint: LOGIN_END_POINT,
    login,
}