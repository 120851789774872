import Vue from 'vue'
import axios from "axios";
import authApi from "./auth"
import router from "../plugins/router";
import storage from "../plugins/storage";

const httpClient = axios.create({
    baseURL: process.env.VUE_APP_FUELING_BACK_BASE_URL,
    //timeout: 1000,
    headers: {
        "Content-Type": "application/json",
    }
});

const getSort = (sortBy, sortDesc) => {
    let sort = [];

    if (sortBy.length > 0) {
        for (let i in sortBy) {
            let sortItem = {
                column: sortBy[i],
            };

            if (i in sortDesc) {
                sortItem.order = sortDesc[i] ? 'DESC' : 'ASC';
            }

            sort.push(sortItem)
        }
    }

    return sort;
}

// interceptor to catch errors
const errorInterceptor = error => {
    let errorText = 'Ошибка сети/сервера';

    if (error.response) {
        switch (error.response.status) {
            case 400:
                errorText = 'Нет данных для отображения'

                break;
            case 401: // authentication error, logout the user
                errorText = 'Ошибка авторизации'

                if (error.response.config.url !== authApi.endpoint) {
                    storage.resetToken()
                    router.push('/login')
                }

                break;

            default:
                errorText = 'Ошибка сервера'
        }
    }

    Vue.notify({
        type: 'error',
        title: 'Ошибка запроса',
        text: errorText
    })

    return Promise.reject(error);
}

// Interceptor for responses
const responseInterceptor = response => {
    let errorText = null

    switch(response.status) {
        case 200:
            if (response.config.url === authApi.endpoint) {
                //TODO: добавлю позже
                console.log('auth response OK');
            } else {
                if (!Object.prototype.hasOwnProperty.call(response.data, 'data') || !Object.prototype.hasOwnProperty.call(response.data, 'status')) {
                    errorText = 'Некорректный ответ сервера'
                } else if (response.data.status !== 'success') {
                    errorText = 'Неизвестная ошибка';

                    if (typeof response.data.message === 'string') {
                        errorText = response.data.message;
                    }
                } else {
                    response.data = response.data.data;
                }
            }

            break;
        default:
            errorText = 'Неизвестная ошибка'
    }

    if (errorText !== null) {
        Vue.notify({
            type: 'error',
            title: 'Ошибка запроса',
            text: errorText,
        })

        return Promise.reject(errorText);
    }

    return response;
}

httpClient.interceptors.response.use(responseInterceptor, errorInterceptor);

httpClient.interceptors.request.use(function (config) {
    if (storage.hasToken()) {
        config.headers.Authorization = 'Bearer ' + storage.getToken();
    }

    return config;
});

export {httpClient, getSort}